<!--
 * @Description: 自建课程
 * @Author: xiawenlong
 * @Date: 2021-04-25 17:36:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 17:29:40
-->
<template>
  <div class="created-one">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="请选择商品：" class="container-item">
        <el-radio-group v-model="form.radio">
          <el-radio v-show="!sendclassid" label="1">课程</el-radio>
          <el-radio label="2">班级</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="form.radio == '1'">
        <el-form-item label="课程名称：" class="container-item">
          <el-input v-model="form.couseName" placeholder="请输入课程名称"></el-input>
          <el-button type="primary" class="searchBtn" @click="searchCourse">查找</el-button>
        </el-form-item>
        <table-list
          title=""
          :loading="loading"
          :data="courseList"
          :columns="columns(this)"
          :options="{ singleCurrent: true }"
          :pager="pager"
          @singleSelectChange="handleSelectionChange"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></table-list>
      </div>
      <div v-if="form.radio == '2'">
        <el-form-item label="班级名称：" class="container-item">
          <el-input v-model="form.className" placeholder="请输入班级名称"></el-input>
          <el-button type="primary" class="searchBtn" @click="searchClass">查找</el-button>
        </el-form-item>
        <table-list
          title=""
          :loading="loading1"
          :data="classList"
          :columns="columns1(this)"
          :options="{ singleCurrent: true }"
          :pager="pager1"
          @singleSelectChange="handleSelectionChange"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
        ></table-list>
      </div>
    </el-form>
    <div class="block">
      <el-button type="primary" class="goNext" @click="next">下一步</el-button>
    </div>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'courseId',
    label: '课程编号',
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'teacherList',
    label: '任课老师',
    formatter: row => {
      let nameArray = row.teacherList.map(element => element.teacherName)
      return nameArray.join('/')
    },
  },
  {
    prop: 'chapterCount',
    label: '章节数',
  },
  {
    prop: 'durationCount',
    label: '课程时长（分钟）',
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
// 表格列配置
const columns1 = () => [
  {
    prop: 'classId',
    label: '班级编号',
  },
  {
    prop: 'className',
    label: '班级名称',
  },

  {
    prop: 'teacherList',
    label: '班主任',
    formatter: row => {
      let nameArray = row.teacherList.map(element => element.studentName)
      return nameArray.join('、')
    },
  },
  {
    prop: 'courseList',
    label: '班级课程',
    formatter: row => {
      let nameArray = row.courseList.map(element => element.courseName)
      return nameArray.join('、')
    },
  },
  {
    prop: 'classCreateTime',
    label: '创建时间',
  },
]
import TableList from '@/components/TableList'
import { courseList, classList } from '@/api/center'
import to from 'await-to'
export default {
  name: 'CreatedOne',
  components: {
    TableList,
  },
  props: {
    sendclassid: {
      type: String,
      // 当为数组类型设置默认值时必须使用数组返回
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      columns,
      columns1,
      loading: false,
      loading1: false,
      classList: [],
      courseList: [],
      currentRow: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        keyword: '',
      },
      pager1: {
        total: 0,
        size: 10,
        current: 1,
        keyword: '',
      },
      form: {
        radio: '2',
        couseName: '',
        className: '',
      },
    }
  },
  mounted() {
    this.getCourseList()
    this.getClassList()
  },
  methods: {
    //下一步
    next() {
      if (this.currentRow.length == 0) return this.$message.warning('请选择商品!')
      this.active = 2
      this.$emit('getNextOne', this.active)
      this.$emit('selectRow', this.currentRow)
    },
    //课程列表
    async getCourseList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(courseList(this.pager))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.courseList = res.data.list
    },
    //搜索课程
    async searchCourse() {
      this.pager.keyword = this.form.couseName
      this.pager.current = 1
      this.getCourseList()
    },
    //搜索班级
    async searchClass() {
      this.pager1.keyword = this.form.className
      this.pager1.current = 1
      this.getClassList()
    },
    //班级列表
    async getClassList() {
      if (this.loading1) return
      this.loading1 = true
      const [res, err] = await to(classList(this.pager1))
      this.loading1 = false
      if (err) return this.$message.warning(err.msg)
      this.pager1.total = res.data.total
      this.classList = res.data.list
    },

    // 单选
    handleSelectionChange(val) {
      this.currentRow = val
    },
    // 课程分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseList()
    },
    // 班级单选
    handleSelectionChange1(val) {
      this.selectClass = val
    },
    //班级分页
    handleSizeChange1(size) {
      this.pager1.current = 1
      this.pager1.size = size
      this.getClassList()
    },
    handleCurrentChange1(current) {
      this.pager1.current = current
      this.getClassList()
    },
  },
}
</script>
<style lang="scss" scoped>
.created-one {
  .block {
    width: 300px;
    margin: 32px auto;
  }
  .container-item {
    margin: 10px 150px;
  }
  .el-input {
    width: 350px;
  }
  .searchBtn {
    margin-left: 20px;
  }
}
</style>

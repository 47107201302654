<template>
  <div class="created-four">
    <img class="created-img" src="../../../../assets/center/created.png" alt="" />
    <div class="created-text">创建成功</div>
    <div class="created-tip">您的商品创建成功，等待审核后就可以上架</div>
    <div class="created-btn">
      <el-button @click="goCenter">商品管理</el-button>
      <el-button type="primary" @click="goCenterAdd">继续创建商品</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {
    goCenter() {
      this.$router.push('/scenter/sallManger')
    },
    goCenterAdd() {
      this.active = 1
      this.$emit('goCenterAdd', this.active)
    },
  },
}
</script>
<style lang="scss" scoped>
.created-four {
  .created-img {
    width: 60px;
    height: 60px;
    display: block;
    margin: 10px auto;
  }
  .created-text {
    font-size: 24px;
    text-align: center;
    color: #333333;
  }
  .created-tip {
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    color: #979797;
  }
  .created-btn {
    width: 250px;
    margin: 20px auto 400px;
  }
}
</style>

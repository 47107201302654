<template>
  <div class="created-two">
    <el-dialog title="设置免费章节" :visible.sync="centerDialogVisible" width="40%" center>
      <table-list
        :data="courseList"
        :columns="columns(this)"
        :options="{ selection: true }"
        @selectionChange="handleSelectionChange"
      ></table-list>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-form ref="form" :model="form" label-width="115px" :rules="rules">
      <el-form-item label="商品标题" prop="name">
        <el-input v-model="form.name" placeholder="请输入商品标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="商品特点" prop="introduction">
        <el-input v-model="form.introduction" placeholder="请输入商品特点" clearable></el-input>
      </el-form-item>

      <el-form-item v-if="form.resourceType == 2" label="商品分类" prop="productCategoryIds">
        <el-cascader
          v-model="form.productCategoryIds"
          :options="options"
          :props="props"
          @change="handleChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item ref="pic" label="商品封面图" prop="pic">
        <upload-image v-model="form.pic" :upload-img="$refs.pic"></upload-image>
        <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
      </el-form-item>
      <el-form-item v-if="form.resourceType == 2">
        <el-checkbox v-model="form.isSupportFreeWatch">免费试看</el-checkbox>
      </el-form-item>
      <el-form-item
        v-if="form.resourceType == 2 && form.isSupportFreeWatch == true"
        label="免费试看章节"
      >
        <div class="addCourse" @click="centerDialogVisible = true">
          <i class="el-icon-plus"></i>添加章节
        </div>
        <div v-for="item in form.sections" :key="item.sectionId">
          <span>{{ item.sectionName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="商品详细描述" prop="description">
        <tinymce ref="editor" v-model="form.description" @onClick="onClick"></tinymce>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="before">上一步</el-button>
        <el-button type="primary" @click="next">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'sectionName',
    label: '课程名称',
  },
  {
    prop: 'durationStr',
    label: '课程时长',
  },
]
import TableList from '@/components/TableList'
import UploadImage from '@/components/UploadImage'
import Tinymce from '@/components/Tinymce'
import { treeList, productInfo } from '@/api/center'
import to from 'await-to'
export default {
  name: 'CreatedTwo',
  components: { UploadImage, Tinymce, TableList },
  props: {
    sendselcelist: {
      type: Object,
      // 当为数组类型设置默认值时必须使用数组返回
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      columns,
      rules: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        introduction: [
          { required: true, message: '请输入2-24个字商品特点', trigger: 'blur', min: 2, max: 24 },
        ],
        productCategoryIds: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
        description: [{ required: true, message: '请输入商品介绍', trigger: 'blur' }],
      },
      form: {
        name: '', //商品名
        introduction: '', //商品特点
        resourceType: this.sendselcelist.resourceType, //资源类型
        resourceId: this.sendselcelist.resourceId, //资源ID
        productCategoryIds: '', //商品分类id
        isSupportFreeWatch: true, //是否支持免费章节
        pic: '', //图片
        sections: [], //免费列表
        description: '', //商品简介
      },
      options: [], //商品分类
      courseList: [], //章节列表
      props: {
        value: 'courseCategoryId',
        label: 'name',
      },
      centerDialogVisible: false,
    }
  },
  mounted() {
    this.productInfo()
    this.getTreeList()
  },
  methods: {
    //上一步
    before() {
      this.active = 1
      this.$emit('getBeforeOne', this.active)
    },
    //下一步
    next() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.active = 3
          this.$emit('getNextTwo', this.active)
          this.$emit('getForm', this.form)
        } else {
          return
        }
      })
    },
    onClick() {},
    //商品详细信息
    async productInfo() {
      const [res, err] = await to(productInfo({ ...this.form }))
      if (err) return this.$message.warning(err.msg)
      this.form.name = res.data.name
      this.form.introduction = res.data.description
      this.form.pic = res.data.pic
      this.form.productCategoryIds = res.data.productCategoryIds
      this.form.description = res.data.detailHtml || ''
      this.courseList = res.data.sections
    },
    //商品分类
    async getTreeList() {
      const [res, err] = await to(treeList())
      if (err) return this.$message.warning(err.msg)
      this.options = res.data
    },
    //商品分类选中
    handleChange(val) {
      this.form.productCategoryIds = val
    },
    handleSelectionChange(val) {
      this.form.sections = val
    },
  },
}
</script>
<style lang="scss" scoped>
.created-two {
  .el-form-item {
    margin: 30px 150px;
  }
  .el-input {
    width: 350px;
  }
  .el-cascader {
    width: 350px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .addCourse {
    width: 128px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    color: #ff7b33;
    cursor: pointer;
  }
}
</style>

<!--
 * @Description: 添加课程资源
 * @Author: xiawenlong
 * @Date: 2021-04-25 09:16:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-08 16:26:01
-->
<template>
  <div class="sallManger-edit">
    <el-dialog title="设置免费章节" :visible.sync="centerDialogVisible" width="40%" center>
      <table-list
        :data="courseList"
        :columns="columns(this)"
        :options="{ selection: true }"
        @selectionChange="handleSelectionChange"
      ></table-list>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">创建商品</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="商品标题" prop="name">
          <el-input v-model="form.name" placeholder="请输入商品标题" clearable></el-input>
        </el-form-item>
        <el-form-item label="商品特点" prop="description">
          <el-input v-model="form.description" placeholder="请输入商品特点" clearable></el-input>
        </el-form-item>
        <el-form-item v-if="form.resourceType == 2" label="商品分类" prop="productCategoryIds">
          <el-cascader
            v-model="form.productCategoryIds"
            :options="options"
            :props="props"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item ref="pic" label="商品封面图" prop="pic">
          <upload-image v-model="form.pic" :upload-img="$refs.pic"></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item v-if="form.resourceType == 2">
          <el-checkbox v-model="form.isSupportFreeWatch">免费试看</el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="form.resourceType == 2 && form.isSupportFreeWatch == true"
          label="免费试看章节"
        >
          <div class="addCourse" @click="centerDialogVisible = true">
            <i class="el-icon-plus"></i>添加章节
          </div>
          <div v-for="item in form.sections" :key="item.sectionId">
            <span>{{ item.sectionName }}</span>
          </div>
        </el-form-item>
        <el-form-item label="商品详细描述" prop="detailHtml">
          <tinymce ref="editor" v-model="form.detailHtml" @onClick="onClick"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Edit">确定修改</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'sectionName',
    label: '课程名称',
  },
  {
    prop: 'durationStr',
    label: '课程时长',
  },
]
import TableList from '@/components/TableList'
import UploadImage from '@/components/UploadImage'
import Tinymce from '@/components/Tinymce'
import { treeList, productUpdate, productDetail } from '@/api/center'
import to from 'await-to'
export default {
  name: 'SallMangerAdd',
  components: { UploadImage, Tinymce, TableList },
  data() {
    return {
      columns,
      rules: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        description: [{ required: true, message: '请输入商品特点', trigger: 'blur' }],
        productCategoryIds: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
        detailHtml: [{ required: true, message: '请输入商品介绍', trigger: 'blur' }],
      },
      form: {
        name: '', //商品名
        description: '', //商品特点
        resourceType: this.$route.query.resourceType, //资源类型
        resourceId: this.$route.query.resourceId, //资源ID
        productCategoryIds: '', //商品分类id
        pic: '', //图片
        productId: this.$route.query.productId,
        detailHtml: '', //商品简介
        isSupportFreeWatch: true, //支持免费试看
        sections: [], //免费章节
      },
      options: [], //商品分类
      props: {
        value: 'courseCategoryId',
        label: 'name',
      },
      courseList: [],
      centerDialogVisible: false,
    }
  },
  mounted() {
    this.productInfo()
    this.getTreeList()
  },
  methods: {
    onClick() {},

    Edit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(productUpdate({ ...this.form }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('修改成功')
          this.$router.push('/scenter/sallManger')
        } else {
          return
        }
      })
    },
    //商品分类选中
    handleChange(val) {
      this.form.productCategoryIds = val
    },
    //多选
    handleSelectionChange(val) {
      this.form.sections = val
    },
    async productInfo() {
      const [res, err] = await to(productDetail({ ...this.form }))
      if (err) return this.$message.warning(err.msg)
      this.form.name = res.data.name
      this.form.description = res.data.description
      this.form.pic = res.data.pic
      this.form.productCategoryIds = res.data.productCategoryIds
      console.log(this.form.productCategoryIds)
      this.form.detailHtml = res.data.detailHtml || ''
      this.courseList = res.data.sections
      this.form.sections = res.data.freeSections
    },
    //商品分类
    async getTreeList() {
      const [res, err] = await to(treeList())
      if (err) return this.$message.warning(err.msg)
      this.options = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
.sallManger-edit {
  .el-form-item {
    margin: 15px 0px;
  }
  .el-input {
    width: 350px;
  }
  .el-cascader {
    width: 350px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .addCourse {
    width: 128px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    color: #ff7b33;
    cursor: pointer;
  }
}
</style>

<!--
 * @Description: 添加课程资源
 * @Author: xiawenlong
 * @Date: 2021-04-25 09:16:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-08 16:26:01
-->
<template>
  <div class="sallManger-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">创建商品</span>
      </div>
      <el-steps :active="active" align-center>
        <el-step title="选择商品"></el-step>
        <el-step title="确定商品信息"></el-step>
        <el-step title="设置价格和优惠"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <createdOne
        v-if="active == 1"
        :sendclassid="classId"
        @selectRow="selectRow"
        @getNextOne="getNextOne"
      ></createdOne>
      <createdTwo
        v-if="active == 2"
        :sendselcelist="selectList"
        @getBeforeOne="getBeforeOne"
        @getNextTwo="getNextTwo"
        @getForm="getForm"
      ></createdTwo>
      <createdThird
        v-if="active == 3"
        :getformlist="form"
        @getBeforeTwo="getBeforeTwo"
        @getNextThird="getNextThird"
      ></createdThird>
      <createdFour v-if="active == 4" @goCenterAdd="goCenterAdd"></createdFour>
    </el-card>
  </div>
</template>
<script>
import createdOne from './components/createdOne'
import createdTwo from './components/createdTwo'
import createdThird from './components/createdThird'
import createdFour from './components/createdFour'
export default {
  name: 'SallMangerAdd',
  components: {
    createdOne,
    createdTwo,
    createdThird,
    createdFour,
  },
  data() {
    return {
      active: 1,
      selectList: [],
      form: {},
      classId: this.$route.query.classId || '',
    }
  },
  mounted() {},
  methods: {
    //第一步传过来选中项
    selectRow(data) {
      this.selectList = data
    },
    //第一步下一步
    getNextOne(val) {
      this.active = val
    },
    //第二步上一步
    getBeforeOne(val) {
      this.active = val
    },
    //第二步下一步
    getNextTwo(val) {
      this.active = val
    },
    //第二步表单传值
    getForm(val) {
      this.form = val
    },
    //第三步上一步
    getBeforeTwo(val) {
      this.active = val
    },
    getNextThird(val) {
      this.active = val
    },
    //继续创建商品
    goCenterAdd(val) {
      this.active = val
    },
  },
}
</script>
<style lang="scss" scoped>
.sallManger-add {
  .block {
    width: 300px;
    margin: 32px auto;
  }
}
</style>

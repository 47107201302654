<template>
  <div class="created-third">
    <el-form ref="form" :model="form" label-width="115px">
      <el-form-item class="checkedOne"
        ><el-checkbox v-model="form.isRetail">普通用户零售定价</el-checkbox>
      </el-form-item>
      <div v-if="form.isRetail">
        <div>
          <el-form-item label="商品售价：">
            <el-input
              v-model="form.price"
              type="number"
              placeholder="请输入商品售价（必填）"
              clearable
            >
            </el-input
            >（元）
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.isPromote">折扣价格零售</el-checkbox>
          </el-form-item>
          <el-form-item label="折扣价格：">
            <el-input
              v-model="form.promotionPrice"
              type="number"
              placeholder="请输入折扣价格"
              :disabled="!form.isPromote"
              clearable
            >
            </el-input
            >（元）
          </el-form-item>
          <el-form-item label="起止时间：">
            <el-date-picker
              v-model="date1"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="!form.isPromote"
              @change="getDate1"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-checkbox v-model="form.isFree">是否免费</el-checkbox>
        </el-form-item>
        <div v-if="form.isFree">
          <el-form-item>
            <el-checkbox v-model="form.isFreeForever">永久</el-checkbox>
          </el-form-item>
          <el-form-item label="起止时间：">
            <el-date-picker
              v-model="date"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="form.isFreeForever"
              @change="getDate"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <el-form-item v-if="form.resourceType == 2" class="checkedOne"
        ><el-checkbox v-model="form.isWholesale"
          >企业批量采购定价<span class="tips_item"
            >(注：建议企业批量采购定价低于普通定价)</span
          ></el-checkbox
        >
      </el-form-item>
      <div v-if="form.isWholesale && form.resourceType == 2">
        <el-form-item
          ><el-checkbox v-model="form.isQuantity">按购买人数采购</el-checkbox>
        </el-form-item>
        <el-form-item label="商品定价：">
          <el-input
            v-model="form.quantityPrice"
            type="number"
            placeholder="请输入商品原价"
            clearable
            :disabled="!form.isQuantity"
          >
          </el-input
          >（元）
        </el-form-item>
        <el-form-item
          ><el-checkbox v-model="form.isBuyout">不限人数一年买断</el-checkbox>
        </el-form-item>
        <el-form-item label="商品定价：">
          <el-input
            v-model="form.buyoutPrice"
            type="number"
            placeholder="请输入商品原价"
            clearable
            :disabled="!form.isBuyout"
          >
          </el-input
          >（元）
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="before">上一步</el-button>
        <el-button type="primary" @click="next">创建商品</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { productCreate } from '@/api/center'
import to from 'await-to'
export default {
  props: {
    getformlist: {
      type: Object,
      // 当为数组类型设置默认值时必须使用数组返回
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      date: null, //免费时间戳
      date1: null, //打折时间戳
      formList: this.getformlist,
      form: {
        isRetail: true, //是否零售
        isFree: false, //是否免费
        isFreeForever: true, //是否永久免费
        freeStartTime: '', //免费开始时间
        freeEndTime: '', //免费结束时间
        price: '', //商品售价
        originalPrice: '', //商品原价
        isPromote: false, //是否折扣价
        promotionPrice: '', //折扣价格
        promotionStartTime: '', //打折开始时间
        promotionEndTime: '', //打折结束时间
        isWholesale: true, //是否企业批量
        isQuantity: false, //是否支持按人购买
        quantityPrice: '', //按人购买价格
        isBuyout: false, //是否支持不限人数一年买断
        buyoutPrice: '', //一年买断价格
        description: this.getformlist.introduction, //商品特点
        detailHtml: this.getformlist.description, //商品详情
        name: this.getformlist.name, //商品名称
        pic: this.getformlist.pic, //商品图片
        productCategoryIds: this.getformlist.productCategoryIds, //商品分类
        resourceId: this.getformlist.resourceId, //资源id:班级id或者课程id
        resourceType: this.getformlist.resourceType, //资源类型:1-班级，2-课程
        sections: this.getformlist.sections, //免费试看章节列表
        isSupportFreeWatch: this.getformlist.isSupportFreeWatch, //是否支持免费是看
      },
    }
  },
  mounted() {
    console.log(this.formList)
  },
  methods: {
    //上一步
    before() {
      this.active = 2
      this.$emit('getBeforeTwo', this.active)
    },
    //下一步
    next() {
      if (this.form.isRetail == false && this.form.isWholesale == false)
        return this.$message.warning('请至少选择一种售卖方式！')
      if (
        this.form.isRetail == true &&
        this.form.isFree == true &&
        this.form.isFreeForever == false &&
        this.date == null
      )
        return this.$message.warning('请选择免费起止时间！')

      if (this.form.isRetail == true && this.form.price === '')
        return this.$message.warning('请输入商品零售售价！')
      if (this.form.isRetail == true && this.form.price < 0)
        return this.$message.warning('商品零售售价不能小于0！')
      if (
        this.form.isRetail == true &&
        this.form.isPromote == true &&
        this.form.promotionPrice === ''
      )
        return this.$message.warning('请输入商品零售打折价格！')
      if (this.form.isRetail == true && this.form.isPromote == true && this.form.promotionPrice < 0)
        return this.$message.warning('商品零售打折价格不能小于0！')

      if (this.form.isRetail == true && this.form.isPromote == true && this.date1 == null)
        return this.$message.warning('请输入打折起止时间！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isQuantity == true &&
        this.form.quantityPrice === ''
      )
        return this.$message.warning('请输入按购买人数采购商品定价！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isQuantity == true &&
        this.form.quantityPrice < 0
      )
        return this.$message.warning('按购买人数采购商品定价不能小于0！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isBuyout == true &&
        this.form.buyoutPrice === ''
      )
        return this.$message.warning('请输入不限人数一年买断商品定价！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isBuyout == true &&
        this.form.buyoutPrice < 0
      )
        return this.$message.warning('不限人数一年买断商品定价不能小于0！')
      this.productCreate()
    },
    //创建商品
    async productCreate() {
      const [, err] = await to(productCreate(this.form))
      if (err) return this.$message.warning(err.msg)
      this.active = 4
      this.$emit('getNextThird', this.active)
    },
    //获取免费时间段
    getDate() {
      if (this.date == null) {
        this.form.freeStartTime = ''
        this.form.freeEndTime = ''
      } else {
        this.form.freeStartTime = this.date[0]
        this.form.freeEndTime = this.date[1]
      }
    },
    //获取打折时间段
    getDate1() {
      if (this.date1 == null) {
        this.form.promotionStartTime = ''
        this.form.promotionEndTime = ''
      } else {
        this.form.promotionStartTime = this.date1[0]
        this.form.promotionEndTime = this.date1[1]
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.created-third {
  .el-form-item {
    margin: 15px 150px;
  }
  .el-input {
    width: 350px;
  }
  .checkedOne {
    background-color: #f6f6f6;
    ::v-deep.el-form-item__content {
      margin-left: 40px !important;
    }
    ::v-deep.el-checkbox__label {
      font-size: 20px !important;
    }
    .tips_item {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
</style>

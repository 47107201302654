<!--
 * @Description: 添加课程资源
 * @Author: xiawenlong
 * @Date: 2021-04-25 09:16:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-30 14:35:06
-->

<template>
  <div class="videoManger-add">
    <el-dialog title="选择跳转课程" :visible.sync="centerDialogVisible" width="40%" center>
      <table-list
        :data="productLists"
        :search-form="searchForm(this)"
        :columns="columns(this)"
        :options="{ singleCurrent: true }"
        :pager="pager"
        @search="onSearch"
        @singleSelectChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">发布短视频</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="视频名称" prop="videoName">
          <el-input
            v-model="form.videoName"
            placeholder="请输入素材名称4-24个汉字"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="短视频分类" prop="categoryId">
          <el-cascader
            v-model="form.categoryId"
            :options="CategoryList"
            :props="props"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item ref="coverImageUrl" label="封面图片">
          <upload-image
            v-model="form.coverImageUrl"
            drag
            :upload-img="$refs.coverImageUrl"
            :width-size="564"
            :height-size="290"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item label="上传素材">
          <el-radio-group v-model="uploadType">
            <el-radio-button label="1">本地上传</el-radio-button>
            <el-radio-button label="2">第三方素材网站</el-radio-button>
          </el-radio-group>
          <div v-if="uploadType == 1">
            <el-upload
              action="/api/third/api/huaWeiUpload/upload/video/local"
              :on-success="handleUploadSuccess"
              :file-list="fileList"
              :limit="1"
            >
              <el-button>选择上传素材</el-button>
              <p slot="tip" class="tip">
                选择素材后，无需等待在这个页面，点击确定后可以查看上传进度
              </p>
            </el-upload>
          </div>
          <div v-if="uploadType == 2">
            <el-form-item label="素材第三方平台">
              <el-select v-model="form.videoPlatform" placeholder="选择视频平台" clearable>
                <el-option label="华为云" value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="素材地址">
              <el-input v-model="form.videoUrl" placeholder="请输入素材地址" clearable></el-input>
            </el-form-item>
            <el-form-item label="视频时长">
              <el-input v-model="form.duration" placeholder="请输入素材时长" clearable></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="选择跳转课程">
          <div class="addCourse" @click="centerDialogVisible = true">
            <i class="el-icon-plus"></i>添加章节
          </div>
          <div class="showList">{{ showList.name }}</div>
        </el-form-item>
        <el-form-item>
          <el-button v-if="!form.id" type="primary" @click="submit">确定</el-button>
          <el-button v-if="form.id" type="primary" @click="submit1">编辑</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'keyword',
  },
]
const columns = () => [
  {
    prop: 'productId',
    label: '课程编号',
  },
  {
    prop: 'name',
    label: '课程名称',
  },
]
import { svCategory, svSave, productList, svInfo, svUpdate } from '@/api/center'
import to from 'await-to'
import TableList from '@/components/TableList'
import UploadImage from '@/components/UploadImage'
export default {
  name: 'VideoMangerAdd',
  components: { UploadImage, TableList },
  data() {
    return {
      searchForm,
      columns,
      rules: {
        videoName: [
          { required: true, message: '请输入短视频名称', trigger: 'blur' },
          { min: 4, max: 12, message: '请输入4~12个字', trigger: 'blur' },
        ],
        categoryId: [{ required: true, message: '请选择短视频分类', trigger: 'change' }],
      },
      form: {
        videoPlatform: '2',
        productId: '',
        categoryId: '',
        coverImageUrl: '',
        duration: '',
        videoId: '',
        videoName: '',
        videoUrl: '',
        id: '',
      },
      uploadType: 1,
      fileList: [],
      props: {
        value: 'categoryId',
        label: 'name',
      },
      CategoryList: [],
      productLists: [],
      pager: {
        current: 1,
        keyword: '',
        size: 10,
        total: 0,
      },
      centerDialogVisible: false,
      showList: '',
    }
  },
  mounted() {
    this.svCategory()
    this.productList()
    this.svInfo()
  },
  methods: {
    handleChange(val) {
      this.form.categoryId = val[0]
    },

    async productList() {
      const [res, err] = await to(productList(this.pager))
      if (err) return this.$message.warning(err.msg)
      this.productLists = res.data.list
      this.pager.total = res.data.total
    },
    async svCategory() {
      const [res, err] = await to(svCategory())
      if (err) return this.$message.warning(err.msg)
      this.CategoryList = res.data
    },
    handleUploadSuccess(response) {
      this.form.videoId = response.data.assetId
      this.$message.success('上传成功')
    },
    async submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(svSave({ ...this.form }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('发布成功')
          this.$router.push('/scenter/videoManger')
        } else {
          return
        }
      })
    },
    async submit1() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(svUpdate({ ...this.form }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          this.$router.push('/scenter/videoManger')
        } else {
          return
        }
      })
    },

    async svInfo() {
      let id = Number(this.$route.query.id)
      if (id) {
        const [res, err] = await to(svInfo({ id: id }))
        if (err) return this.$message.warning(err.msg)
        this.form.categoryId = res.data.categoryId
        this.form.coverImageUrl = res.data.coverImageUrl
        this.form.duration = res.data.duration
        this.form.name = res.data.categoryId
        this.form.productId = res.data.productId
        this.showList = { productId: res.data.productId, name: res.data.name }
        this.form.videoId = res.data.videoId
        this.form.videoName = res.data.videoName
        this.form.videoPlatform = res.data.videoPlatform
        this.form.videoUrl = res.data.videoUrl
        this.form.id = res.data.id
      } else {
        return
      }
    },
    onSearch(queryParams) {
      this.pager.current = 1
      this.pager.keyword = queryParams.keyword
      this.productList()
    },
    // 单选
    handleSelectionChange(val) {
      this.form.productId = val.productId
      this.showList = val
    },
    // 课程分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.productList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.productList()
    },
  },
}
</script>
<style lang="scss" scoped>
.videoManger-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  .addCourse {
    width: 128px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    color: #ff7b33;
    cursor: pointer;
  }
  .showList {
    font-size: 20px;
    color: #ff7b33;
  }
}
</style>
